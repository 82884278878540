const BASE_URL = "https://api-beta.lywo.in/"; /* Stage */

export const ApiConfig = {
  BASE_URL_SHARE: "",

  LOGIN_WITH_UNIQUE_CODE: BASE_URL + "api/Account/GetUniqueCodeJobDetails",
  REGISTRATION_FORM: BASE_URL + "api/Account/AutheriseEmailAddCandidate",
  ASSESMENT_TEST:
    BASE_URL + "api/Assessment/GetAssessmentQustionsWithPreviousAnswers",
  TOKEN_ACCESS: BASE_URL + "api/Account/GetToken",
  ADD_ASSESSMENT_RESULT: BASE_URL + "api/Assessment/SubmitAssessment",
  ASSESSMENT_PICTURE: BASE_URL + "api/Assessment/GetImage/",
  GET_SPECIALIZATIONS: BASE_URL + "api/CommonDropDown/GetSpecializations",
  SUBMIT_ADDITIONINFO:
    BASE_URL + "api/CandidateMgmt/AddEditCandidateAdditionaldtls",
  GET_ADDITIONINFO: BASE_URL + "api/CandidateMgmt/GetAdditionalDtls",
  GET_LANG: BASE_URL + "api/Account/GetLanguagePreference?UserId=",
  UPDATE_LANG: BASE_URL + 'api/Account/UpdateLanguagePreference?',
  GET_CUSTOM_QUESTIONS : BASE_URL+"api/Organization/GetCustomQuestion",
};
export default ApiConfig;
