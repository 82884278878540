import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../contaxt/AppContext";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const DropdownLanguage = ({ showDropDown, setShowDropDown }) => {
  const { SetChangeLang, ChangeLang } = useContext(AppContext);
  const { i18n, t } = useTranslation();

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  useEffect(() => {
    i18n.changeLanguage(ChangeLang);
  }, [i18n, ChangeLang]);

  const handleLangChange = (lang) => {
    SetChangeLang(lang);
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
    setShowDropDown(false);

    simplePostCall(
      ApiConfig?.UPDATE_LANG +
        `userId=${localStorage.getItem("customer_id")}&language=${lang}`
    ).then((res) => {
      if (res?.succeded) {
        console.log("success");
      }
    });
  };

  const currentLanguage = localStorage.getItem("language");

  return (
    <div>
      {/* <div
         className="lang-style text-muted"
        style={{
          backgroundColor: currentLanguage === "sel" ? "#444CE7" : "transparent",
          color: currentLanguage === "sel" ? "grey" : "grey",
        }}
      >
        Select Language
      </div> */}
      <div
        onClick={() => handleLangChange("en")}
        className="lang-style"
        style={{
          backgroundColor: ChangeLang === "en" ? "#444CE7" : "transparent",
          color: ChangeLang === "en" ? "white" : "black",
        }}
      >
        English
      </div>

      <div
        onClick={() => handleLangChange("hi")}
        className="lang-style"
        style={{
          backgroundColor: ChangeLang === "hi" ? "#444CE7" : "transparent",
          color: ChangeLang === "hi" ? "white" : "black",
        }}
      >
        Hindi
      </div>

      <div
        onClick={() => handleLangChange("gj")}
        className="lang-style"
        style={{
          backgroundColor: ChangeLang === "gj" ? "#444CE7" : "transparent",
          color: ChangeLang === "gj" ? "white" : "black",
        }}
      >
        Gujrati
      </div>

      <div
        onClick={() => handleLangChange("kn")}
        className="lang-style"
        style={{
          backgroundColor: ChangeLang === "kn" ? "#444CE7" : "transparent",
          color: ChangeLang === "kn" ? "white" : "black",
        }}
      >
        Kannada
      </div>

      {/* <div
        onClick={() => handleLangChange("tl")}
        className="lang-style"
        style={{
          backgroundColor: ChangeLang === "tl" ? "#444CE7" : "transparent",
          color: ChangeLang === "tl" ? "white" : "black",
        }}
      >
        Tamil
      </div> */}

      <div
        onClick={() => handleLangChange("te")}
        className="lang-style"
        style={{
          backgroundColor: ChangeLang === "te" ? "#444CE7" : "transparent",
          color: ChangeLang === "te" ? "white" : "black",
        }}
      >
        Telugu
      </div>
    </div>
  );
};

export default DropdownLanguage;
