import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../multiLang/translationEN.json";
import translationHI from "../multiLang/translationHI.json";
import translationTE from "../multiLang/translationTE.json";
import translationGJ from "../multiLang/translationGJ.json";
import translationKN from "../multiLang/translationKN.json";
import translationTL from "../multiLang/tranlationTL.json";
const resources = {
  en: { translation: translationEN },
  hi: { translation: translationHI },
  gj: { translation: translationGJ },
  kn: { translation: translationKN },
  // tl : {translation : translationTL},
  te: { translation: translationTE },
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;